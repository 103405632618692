import * as React from "react";
import { Link } from "gatsby";
import Waves from '../components/Waves/Waves';

// markup
const NotFoundPage = () => {
  return (
    <main>
      <title>Not found</title>

      <div style={{ padding: 50, color: '#fff' }}>
        <h1>Page not found</h1>
        
        <Link to="/">Go home</Link>
      </div>
      
      <Waves />
    </main>
  )
}

export default NotFoundPage
